<template>
  <div class="">
    <b-card v-if="!showDetail">
      <div class="flex items-start">
        <b-button
          v-if="userData.is_komship === 1 && userData.is_kompack === 1"
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$emit('back')"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="text-black mb-2 text-2xl font-semibold">
          Riwayat Pengajuan Packing
        </div>
      </div>
      <b-row
        align-v="center"
        align-h="end"
      >
        <b-col cols="3">
          <v-select
            key="id"
            v-model="warehouse"
            :options="listWarehouses"
            :reduce="option => option"
            :clearable="false"
            label="name"
            placeholder="Semua Gudang"
          />
        </b-col>
        <b-col cols="3">
          <DateRangePicker
            ref="picker"
            v-model="dateRange"
            :locale-data="locale"
            :ranges="ranges"
            :opens="'left'"
            class="w-full mt-[5px]"
          >
            <template
              v-slot:input="picker"
            >
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="mr-1">
                  <span
                    v-if="
                      YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(today) && YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)
                    "
                    style="color: #828282 !important"
                  >
                    Hari ini
                  </span>
                  <span
                    v-else-if="
                      YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)
                    "
                    style="color: #828282 !important"
                  >
                    7 Hari Terakhir
                  </span>
                  <span
                    v-else-if="
                      YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last30)
                    "
                    style="color: #828282 !important"
                  >
                    30 Hari Terakhir
                  </span>
                  <span
                    v-else-if="
                      YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(kompackDate) || YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)
                    "
                    style="color: #828282 !important"
                  >
                    Semua Tanggal
                  </span>
                  <span
                    v-else-if="
                      YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(firstDateOfMonth) && YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(lastDateOfMonth)
                    "
                    style="color: #828282 !important"
                  >
                    Bulan ini
                  </span>
                  <span
                    v-else
                    style="color: #828282 !important"
                  > Custom </span>
                </div>
                <div class="padding-arrow">
                  <b-img src="https://storage.googleapis.com/komerce/assets/icons/arrow-filter.svg" />
                </div>
              </div>
            </template>
          </DateRangePicker>
        </b-col>
      </b-row>
      <b-table
        class="mt-6"
        :fields="packingHistoryFields"
        :items="packingHistoryList"
        :busy="loading"
        show-empty
        :empty-text="'Tidak ada data'"
      >
        <template #cell(tanggal_order)="data">
          <div>
            {{ data.item.tanggal_order }}
          </div>
          <div class="text-sm text-[#626262]">
            {{ data.item.jam_order }}
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
              :variant="setStatusBadgeVariant(data.value).variant"
          >
            {{ setStatusBadgeVariant(data.value).value }}
          </b-badge>
        </template>
        <template #cell(aksi)="data">
          <b-link
            class="text-[#08A0F7]"
            @click="showDetail = true, detailId = data.value"
          >
            Lihat Detail
          </b-link>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </b-card>
    <div v-if="showDetail">
      <DetailPacking
        :detail-id="detailId"
        @back="showDetail = false"
      />
    </div>
  </div>
</template>

<script>
import { YEAR_MONTH_DAY, DAY_WITH_FULL_DATE, TIME } from '@/libs/formatDate'
import {
  today, last7, last30, firstDateOfMonth, lastDateOfMonth, kompackDate,
} from '@/store/helpers'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import vSelect from 'vue-select'
import { newAxiosIns } from '@/libs/axios'
import DetailPacking from './DetailPacking.vue'

export default {
  components: {
    vSelect, DateRangePicker, DetailPacking,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDetail: false,
      detailId: '',

      packingHistoryFields: [
        {
          key: 'tanggal_order', label: 'Tanggal Order', thClass: '!capitalize !text-black', tdClass: '!text-black',
        },
        {
          key: 'mitra_gudang', label: 'Mitra Gudang', thClass: '!capitalize !text-black', tdClass: '!text-black',
        },
        {
          key: 'status', label: 'Status', thClass: '!capitalize !text-black', tdClass: '!text-black',
        },
        {
          key: 'aksi', label: 'Aksi', thClass: '!capitalize !text-black', tdClass: '!text-black',
        },
      ],
      packingHistoryList: [],

      warehouse: {
        name: 'Semua Gudang',
        id: '',
        mitra_id: '',
      },
      listWarehouses: [],

      loading: false,

      dateRange: {
        startDate: kompackDate,
        endDate: today,
      },
      DAY_WITH_FULL_DATE,
      YEAR_MONTH_DAY,
      TIME,
      kompackDate,
      today,
      last7,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      },
      ranges: {
        'Hari ini': [today, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan ini': [firstDateOfMonth, lastDateOfMonth],
        'Semua ': [kompackDate, today],
      },
    }
  },
  watch: {
    warehouse: {
      handler() {
        this.getPackingHistory()
      },
    },
    dateRange: {
      handler() {
        this.getPackingHistory()
      },
    },
  },
  beforeMount() {
    this.getWarehouses()
    this.getPackingHistory()
  },
  methods: {
    setStatusBadgeVariant(status) {
      if (status === 'submitted') {
        return {
          value: 'Diajukan',
          variant: 'light-info',
        }
      }
      if (status === 'canceled') {
        return {
          value: 'Dibatalkan',
          variant: 'light-secondary',
        }
      }
      return {
        value: 'Dipacking',
        variant: 'light-warning',
      }
    },
    getPackingHistory() {
      this.loading = true
      const params = {
        warehouse_id: this.warehouse.id,
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
      }
      newAxiosIns.get('kompack/api/v1/outbounds', { params })
        .then(res => {
          this.loading = false
          this.packingHistoryList = (res.data.data || []).map(item => ({
            tanggal_order: DAY_WITH_FULL_DATE(item.order_date),
            jam_order: TIME(item.order_date),
            mitra_gudang: item.warehouse_name,
            status: item.status,
            aksi: item.id,
          }))
        })
        .catch(err => {
          this.loading = false
          console.error(err)
        })
    },
    async getWarehouses() {
      await newAxiosIns.get(`/komship/api/v1/warehouses/partners/${this.userData.partner_detail.id}`)
        .then(res => {
          this.listWarehouses = [{ name: 'Semua Gudang', id: '', mitra_id: '' }, ...res.data.data]
        })
        .catch(err => {
          console.error(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.padding-arrow {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
